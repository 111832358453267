@import './themes';

.rInput {
    margin-bottom: 8px;
    align-items: center;

    i {
        color: $primTextLightGray;
        font-size: 22px;
    }

    &_label {
        font-size: 14px;
        color: $primLightGray;
        margin-bottom: 0;
    }

    textarea,
    input:not([type='checkbox']):not([type='submit']),
    select {
        min-width: 70px;
        width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 6px 6px 5px;
        font-size: 14px;
        color: $primTextDark;
        transition: border 0.15s, box-shadow 0.15s;
        vertical-align: middle;

        &::-webkit-input-placeholder {
            color: $primTextLightGray;
        }

        &::-moz-placeholder {
            color: $primTextLightGray;
        }

        &:-ms-input-placeholder {
            color: $primTextLightGray;
        }

        &:-moz-placeholder {
            color: $primTextLightGray;
        }

        &:focus,
        &:hover {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
            border: 1px solid $primBorderLight;
            outline: none;
            transition: border 0.2s ease, box-shadow 0.2s ease;

            @include themify(true) {
                color: themed('primColor') !important;
            }
        }
        &:read-only,
        &:disabled {
            border-color: transparent;
        }
    }

    textarea {
        line-height: 1.6;
        resize: none;
        padding: 20px 18px;
    }

    &:hover {
        i,
        input {
            color: $primTextBlue;
        }
    }

    &:focus-within {
        i {
            color: $primTextBlue;
        }
    }

    &_checkbox {
        &-input {
            display: none;
            width: 0;
            height: 0;
            border-radius: 3px;
        }

        &-check {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 3px;
            border-width: 2px;
            border-style: solid;
            margin-left: 15px;

            &:before {
                width: 16px;
                height: 16px;
                margin-left: -2px;
                margin-top: -2px;
                font-family: 'icomoon' !important;
                content: '\e919';
                display: none;
                color: #fff;
                font-size: 15px;
            }

            @include themify(true) {
                border-color: themed('primColor') !important;
            }
        }

        &-input:checked ~ &-check {
            &:before {
                display: block;
            }

            @include themify(true) {
                background-color: themed('primColor') !important;
            }
        }
    }
}

.rSearch {
    display: flex;
    align-items: center;
    background-color: rgba($primLightGray, 0.12);
    border-radius: 100px;
    padding: 11px 18px;
    margin-right: 30px;
    width: 310px;

    i {
        display: inline-block;
        color: $primLightGray;
        font-size: 22px;
        vertical-align: middle;
        margin-right: 8px;
    }

    input {
        color: #fff;
        vertical-align: middle;
        background-color: transparent;
        font-size: 16px;
        font-weight: 600;
        flex: 1;

        &::-webkit-input-placeholder {
            color: $primLightGray;
            font-weight: 60 0;
        }

        &::-moz-placeholder {
            color: $primLightGray;
            font-weight: 600;
        }

        &:-ms-input-placeholder {
            color: $primLightGray;
            font-weight: 600;
        }

        &:-moz-placeholder {
            color: $primLightGray;
            font-weight: 600;
        }
    }

    &-outline {
        border: 1px solid $primTextGray;
        background-color: transparent;

        i {
            color: $primTextGray;
        }

        input {
            color: $primTextGray;

            &::-webkit-input-placeholder {
                color: $primTextGray;
                font-weight: 300;
            }

            &::-moz-placeholder {
                color: $primTextGray;
                font-weight: 300;
            }

            &:-ms-input-placeholder {
                color: $primTextGray;
                font-weight: 300;
            }

            &:-moz-placeholder {
                color: $primTextGray;
                font-weight: 300;
            }
        }
    }
}

.dialogInput {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 0;
    margin-bottom: 10px;
    &.border {
        border-radius: 8px;
        border: 1px solid $primBorderLight;
    }

    label {
        color: $primLightGray;
        margin-bottom: 0;
    }

    input,
    select {
        color: $primTextGray;
        border: 1px solid $primBorderLight;
        border-radius: 8px;
        height: 38px;
        padding: 6px 12px 4px;
        color: $primTextDark;
        width: 100%;

        &:hover {
            @include themify(true) {
                color: themed('primColor') !important;
            }
        }
        &:focus {
            outline: none;
        }
    }

    span {
        display: inline-block;
        flex: 1;
        color: $primTextGray;
        margin-bottom: -2px;
    }

    input[type='file'] {
        display: none;
    }

    i {
        font-size: 22px;

        @include themify(true) {
            color: themed('primColor') !important;
        }
    }

    &:focus-within {
        @include themify(true) {
            border-color: themed('primColor') !important;
        }
    }
}

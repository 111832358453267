@import './../styles/_colors';

.rTable {
    &_header {
        display: flex;
        align-items: center;
        padding: 18px 16px;

        .rTable_cell {
            font-size: 12px;
            text-transform: uppercase;
            color: $primTextGray;
        }
    }

    &_line {
        display: flex;
        align-items: center;
        position: relative;
        height: 68px;
        padding: 0 16px;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        margin-bottom: 10px;
        border: 1px solid transparent;
        cursor: pointer;
        transition: box-shadow .25s, border-color .25s;

        &:hover {
            border-color: rgba($primDefault, .5);
            box-shadow: 0 3px 6px rgba($primDefault, .5);
            transition: box-shadow .3s ease, border-color .3s ease;

            .cell-editing {
                opacity: 1;
                transition: opacity .2s ease;
            }
        }
    }

    &_cell {
        flex: 1;
        padding-left: 8px;
        font-size: 15px;
        margin-bottom: -4px;
        color: $primTextDark;

        i {
            vertical-align: sub;
            font-size: 22px;
        }

        &.cell-editing {
            display: flex;
            align-items: center;
            opacity: 0;
            text-align: right;
            transition: opacity .15s;
            position: absolute;
            right: 0;
            background-color: #fff;
            height: 60px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.5970763305322129) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%);
            padding-left: 50px;
            padding-right: 16px;

            i {
                color: $primTextBlue;
                font-size: 22px;
                cursor: pointer;
                user-select: none;
                padding: 4px;
                vertical-align: middle;
                margin-left: 12px;

                &:hover {
                    color: darken($primTextBlue, 10%);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        &_cell {
            &.cell-editing {
                position: inherit;
                i {
                    padding-left: 24px;
                }
            }
        }
    }
}

.rBox {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);

    &_header {
        padding: 19px 24px 17px;
        color: $primTextGray;
        font-size: 13px;
        text-transform: uppercase;
        border-bottom: 1px solid $primBorderLight;

        p {
            font-size: 13px;
        }
    }

    &_content {
        padding: 24px 24px;
    }

    &_text {
        &_price {
            font-size: 16px;
            color: $primTextBlue;
            font-weight: 700;
        }
    }

    &_line {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 14px;

        &>div,
        p,
        span {
            flex: 1;
        }

        &>div {
            color: #455A64;
            font-weight: 700;
        }

        &>span {
            color: $primTextDark;
        }

        input {
            border: 1px solid $primBorderLight;
            border-radius: 4px;
            width: 90px;
            height: 30px;
            color: $primColor;
            padding: 2px 10px 0;
            font-size: 14px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &_footer {
        border-top: 1px solid $primBorderLight;
        padding: 20px 24px;

        b {
            color: $primTextBlue;

            &.black {
                color: $primTextDark;
            }

            &.green {
                color: $primGreen;
            }
            
            &.red {
                color: $primRed;
            }

        }

        p {
            text-transform: uppercase;
            color: #000;
        }
    }
}

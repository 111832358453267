.rPagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &_number {
        text-align: center;
        width: 36px;
        height: 36px;
        border: 1px solid transparent;
        line-height: 36px;
        border-radius: 8px;
        color: $primTextGray;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
        margin-right: 4px;

        &.isActive {
            color: $primDefault;
            border: 1px solid $primDefault;
        }

        &:hover {
            color: $primDefault;
        }
    }

    &_dots {
        color: $primTextGray;
        margin-right: 20px;
        margin-left: 20px;
    }

    &_next,
    &_prev {
        @extend .rPagination_number;
        i{
            color: $primDefault;
            font-size: 28px;
        }

        &:hover{
            color: darken($primDefault, 10%);
        }
    }
}

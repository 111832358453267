$primColor:             #304256;
$primDefault:           #5483B4;
$primLightGray:         #A4ACB5;
$primLightGray:         #A4ACB5;

$primTextGray:          #78909C;
$primTextLightGray:     #AEBCC3;
$primTextBlue:          #2B89EB;
$primTextDark:          #455A64;

$primBorderLight:       #E5E5E5;

$primYellow:            #BAA23D;
$primHoverYellow:       #AD983E;
$primOrange:            #E08758;
$primHoverOrange:       #C96F3F;
$primPink:              #D36D9C;
$primHoverPink:         #BF5E8B;
$primGreen:             #4EC18C;
$primHoverGreen:        #3EAD7A;
$primRed:               #EB3E50;
$primBlue:              #459CFC;
$primHoverBlue:         #2A7ACC;
$primPurple:            #7463D3;
$primHoverPurple:       #6552C9;

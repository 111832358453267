@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9cp19r');
  src:  url('fonts/icomoon.eot?9cp19r#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9cp19r') format('truetype'),
    url('fonts/icomoon.woff?9cp19r') format('woff'),
    url('fonts/icomoon.svg?9cp19r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minor_crash:before {
  content: "\e923";
}
.icon-label:before {
  content: "\e922";
}
.icon-web:before {
  content: "\e921";
}
.icon-minus:before {
  content: "\e920";
}
.icon-more-info:before {
  content: "\e91a";
}
.icon-lock:before {
  content: "\e91b";
}
.icon-unlock:before {
  content: "\e91c";
}
.icon-state:before {
  content: "\e91d";
}
.icon-deposit-money:before {
  content: "\e91e";
}
.icon-closing:before {
  content: "\e91f";
}
.icon-check:before {
  content: "\e919";
}
.icon-edit-amount:before {
  content: "\e918";
}
.icon-warning:before {
  content: "\e917";
}
.icon-folder:before {
  content: "\e916";
}
.icon-dollar:before {
  content: "\e914";
}
.icon-print:before {
  content: "\e915";
}
.icon-user:before {
  content: "\e913";
}
.icon-percents:before {
  content: "\e910";
}
.icon-duplicate:before {
  content: "\e911";
}
.icon-wallet:before {
  content: "\e912";
}
.icon-email:before {
  content: "\e90d";
}
.icon-phone:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e90f";
}
.icon-contract:before {
  content: "\e90c";
}
.icon-arrow-back:before {
  content: "\e90b";
}
.icon-file-pdf .path1:before {
  content: "\e908";
  color: rgb(208, 77, 78);
}
.icon-file-pdf .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-pdf .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-download:before {
  content: "\e907";
}
.icon-clock:before {
  content: "\e900";
}
.icon-delete:before {
  content: "\e901";
}
.icon-edit:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-add:before {
  content: "\e904";
}
.icon-archive:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}

.dx-datagrid {
    background-color: transparent;

    .dx-datagrid-rowsview {
        border: 0;

        .dx-row {
            border: 0;
        }
    }

    .dx-datagrid-table  tr td {
        padding-left: 12px;
        padding-right: 12px;

        &:first-of-type {
            padding-left: 26px;
        }
    }

    .dx-datagrid-headers {
        border: 0;

        .dx-header-row {
            td {
                font-size: 12px;
                color: $primTextGray;
                text-transform: uppercase;
                border: 0;
                padding-bottom: 22px;
                padding-top: 8px;

            }
        }
    }

    .dx-datagrid-rowsview {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);

        .dx-datagrid-content {
            border: 0;

            .dx-data-row {
                height: 68px;
                border-bottom: 1px solid rgba($primDefault, .5);

                td {
                    color: $primTextDark;
                    font-size: 14px;
                    border: 0;
                    vertical-align: middle;
                    background-color: #fff;
                    cursor: pointer;
                }

                &:hover {
                    td {
                        background-color: #F2F8FE;
                    }
                }
            }
        }

    }
}

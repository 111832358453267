$themes: (pink: (primColor: $primPink,
        primColorHover: $primHoverPink,
        textColor: #fff,
        primLightColor: rgba($primPink, .18)),

    green: (primColor: $primGreen,
        primColorHover: $primHoverGreen ,
        textColor: #fff,
        primLightColor: rgba($primGreen, .18)),

    yellow: (primColor: $primYellow,
        primColorHover: $primHoverYellow,
        textColor: #fff,
        primLightColor: rgba($primYellow, .18)),

    blue: (primColor: $primBlue,
        primColorHover: $primHoverBlue,
        textColor: #fff,
        primLightColor: rgba($primBlue, .18)),

    purple: (primColor: $primPurple,
        primColorHover: $primHoverPurple,
        textColor: #fff,
        primLightColor: rgba($primPurple, .18)),

    orange: (primColor: $primOrange,
        primColorHover: $primHoverOrange,
        textColor: #fff,
        primLightColor: rgba($primOrange, .18)));

@mixin themify($globalComponent: false) {

    @each $theme,
    $map in $themes {
        @if ($globalComponent) {
            .theme-#{$theme} & {
                $theme-map: () !global;

                @each $key,
                $submap in $map {
                    $value: map-get(map-get($themes, $theme), '#{$key}');
                    $theme-map: map-merge($theme-map,
                            ($key: $value,
                            )) !global;
                }

                @content;
                $theme-map: null !global;
            }
        }

        @else {
            :host-context(.theme-#{$theme}) & {
                $theme-map: () !global;

                @each $key,
                $submap in $map {
                    $value: map-get(map-get($themes, $theme), '#{$key}');
                    $theme-map: map-merge($theme-map,
                            ($key: $value,
                            )) !global;
                }

                @content;
                $theme-map: null !global;
            }
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

@mixin rArrowDown($col: #fff, $size: 5px) {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: $size solid transparent !important;
    border-right: $size solid transparent !important;
    border-top: $size solid $col;
}

.rArrow {
    @include rArrowDown(rgba(#fff, .68), 5px);
}

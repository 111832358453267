@import '../node_modules/bootstrap/scss/_functions';
@import '../node_modules/bootstrap/scss/_variables';
@import './../node_modules/bootstrap/scss/_mixins';
@import './assets/iconfont/style.css';
@import './styles/styles.scss';

* {
    font-family: 'Overpass', sans-serif;
    letter-spacing: 0.6px;
    line-height: normal;
}

html {
    min-height: 100%;
}

body {
    background-color: #F7F9FA; 
    min-height: 100%;
    height: 100%;
}

h1 {
    font-size: 28px;
    color: $primColor;
}

h2 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 20px;
    color: $primColor;
}

h3 {
    font-size: 20px;
    margin: 0;
    color: $primColor;
    font-weight: 300;
}

h4 {
    font-size: 18px;
    margin: 0;
    color: $primColor;
    font-weight: 4 00;
}

input,
textarea {
    display: inline-block;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    color: $primTextDark;

    &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder {
        color: $primTextLightGray;
    }

    &::-moz-placeholder {
        color: $primTextLightGray;
    }

    &:-ms-input-placeholder {
        color: $primTextLightGray;
    }

    &:-moz-placeholder {
        color: $primTextLightGray;
    }
}

.container {
    max-width: none; 
   padding: 0 3vw 0 3vw; 
}

a {
    color: #0366d6;
    &:hover{
        text-decoration: none;
    }
}

code {
    color: #e01a76;
}

p {
    color: $primTextGray;
    font-size: 14px;
    margin-bottom: 0;
}

i {
    vertical-align: middle;
}

.contentHeader{
  padding-left: 28px;
}

button {
    border: 0;
    outline: none;
    padding: 0;
    border-radius: 8px;
    user-select: none;
    background-color: transparent;
    transition: background-color .25s;

    i {
        transition: background-color .25s;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        transition: background-color .3s ease;

        i {
            transition: background-color .3s ease;
        }
    }
}

@media print {
  body {
    background-color: white !important;
  }  
}

.lds-spinner {
    color: official;
    display: inline-block;
    position:  fixed;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #000;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
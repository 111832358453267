.rTag {
    display: inline-block;
    max-height: 30px;
    font-size: 14px;
    color: $primTextDark;
    background-color: $primBorderLight;
    border-radius: 100px;
    padding: 8px 14px 5px;
    transition: background-color .25s;
    vertical-align: middle;
    margin-right: 8px;

    @include themify(true) {
        background-color: themed('primColor') !important;
        color: #fff;
    }

    i,
    span {
        vertical-align: middle;
    }
    .rArrow{
        margin-left: 8px;
    }

    &_add {
        text-align: center;
        height: 30px;
        width: 30px;
        color: $primTextGray;
        padding: 0;
        cursor: pointer;

        i {
            font-size: 20px;
            line-height: 31px;
        }

        &:hover {
            background-color: darken($primBorderLight, 10%);
            transition: background-color .3s ease;
        }

        &.dark {
            color: #fff;
            background-color: $primTextLightGray;

            &:hover {
                background-color: darken($primTextLightGray, 10%);
            }
        }
    }

}

.l-contentHeader {
    width: 100%;
    background-color: $primColor;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.03);
    padding-bottom: 13px;
}

.contentHeader {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 94px;
    padding: 32px 15px 16px;

    .icon-arrow-back {
        font-size: 20px;
        color: $primTextLightGray;
        margin-right: 20px;
        margin-top: -4px;
        cursor: pointer;
        transition: color .15s;

        &:hover {
            transition: color .2s ease;
            color: darken($primTextLightGray, 10%);
        }
    }

    h1 {
        white-space: nowrap;
        color: #fff;
        font-size: 28px;
        margin: 0;
        font-weight: 400;
        margin-right: 30px;
        padding-bottom: 16px;
    }

    &_actions {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    @include media-breakpoint-up(md) {
        .l-contentHeader {
            padding-bottom: 0px;
        }
        padding: 15px;

        h1 {
            padding-bottom: 0;
        }
    }
}

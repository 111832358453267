@import './../styles/themes';

.dropdown {
    display: inline-block;

    &-dropIcon {
        margin-left: 14px;
        margin-right: -4px !important;
        font-size: 20px !important;
    }

    &-menu {
        margin: 0;
        margin-top: 12px;
        background-color: $primColor;
        border-radius: 8px;
        padding: 10px 0;
        min-width: 250px;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.06);
    }

    &-item {
        width: 100%;
        padding: 17px 24px 14px;
        color: #fff;
        border-radius: 0;

        &:hover {
            @include themify(true) {
                background-color: themed('primColor') !important;
                color: themed('textColor') !important;
            }
        }
    }

    &-text {
        font-size: 14px;
        font-weight: 600;
    }
}

@import './../styles/themes';

.rBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    color: #fff;
    background-color: #5483B4;
    padding: 17px 24px 13px;
    margin: 8px;
    transition: background-color .25s;
    font-size: 13px;
    border-radius: 8px;

    &.big {
        height: 54px;
    }

    i {
        display: block;
        margin-right: 12px;
        margin-top: -3px;
        font-size: 22px;

        &:first-of-type {
            margin-left: -6px;
        }
    }

    &.cancel {
        background-color: #F4F4F4;
        color: $primTextGray;
    }

    &:hover {
        background-color: #BAA23D;
        transition: background-color .3s ease;

        @include themify(true) {
            background-color: themed('primColorHover') !important;
        }

        &.cancel {
            background-color: darken(#F4F4F4, 10%) !important;
        }

    }
}

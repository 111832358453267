.cdk-overlay-backdrop {
    background-color: rgba(0, 0, 0, .35);
}

.mat-dialog-container {
    padding: 0 !important;
    overflow: visible !important;
    border-radius: 8px !important;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.16) !important;
}

/* .cdk-overlay-pane {
    display: inline-block;
    position: relative;
    text-align: left;
    white-space: normal;
} */

.rModal {
    &_header {
        display: flex;
        align-items: center;
        padding: 40px 40px 20px;

        &>i {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            border-radius: 50%;
            width: 48px;
            height: 48px;

            @include themify(true) {
                color: themed('primColor') !important;
                background-color: themed('primLightColor') !important;
            }
        }

        &>h3 {
            font-size: 24px;
            margin: 0;
            margin-bottom: -6px;
            margin-left: 26px;
            color: $primColor;

            b {
                font-weight: 700;
            }
        }

        .rSearch {
            border-color: rgba(#6D8491, .3);
        }
    }

    &_content {
        padding: 20px 40px;

        textarea {
            border: 1px solid $primTextLightGray;
        }
    }

    &_footer {
        text-align: right;
        padding: 30px;

        button {
            height: auto;
            min-width: 165px;
            padding: 20px 0 17px;
        }
    }

    &_submitBtn {
        @extend .rBtn;

        @include themify(true) {
            background-color: themed('primColor') !important;
        }
    }
}
